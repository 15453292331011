export const technicalSkillsConfig = [
  "HTML5",
  "CSS3",
  "JavaScript",
  "kdb+",
  "React",
  "NPM",
  "Git",
  "Firebase",
  "Tableau",
];
